<div class="d-flex justify-content-start gap-3">
  <button
    *ngIf="!hideDirections && directionsLink"
    (click)="
      setAnalyticsEvent('website-transfer', 'directions-link');
      setAlertPrompt('directions');
      emitOpenExternalUrl(directionsLink)
    "
    class="btn btn-lg btn-outline-primary d-flex flex-column flex-wrap gap-1 align-items-center text-uppercase justify-content-center mt-4"
  >
    <span>
      <svg
        class="icon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.4669 20.1013C13.7452 20.1013 13.9726 19.9545 14.1625 19.7671L21.4595 12.9953C21.9253 12.5533 22 12.3054 22 12.0507C22 11.7951 21.9364 11.548 21.4595 11.1052L14.1625 4.32815C13.9685 4.14772 13.7469 4 13.4685 4C13.0757 4 12.874 4.30545 12.874 4.67369V8.75022H12.448C6.78572 8.75022 3 11.9222 3 19.4185C3 19.8349 3.24598 20.1013 3.57105 20.1013C3.8546 20.1013 4.04361 19.9628 4.20133 19.5749C5.47081 16.6168 8.05926 15.4396 12.448 15.4396H12.874V19.4326C12.874 19.8009 13.0757 20.1013 13.4669 20.1013Z"
          fill="currentColor"
        />
      </svg>
      {{ 'APPOINTMENT.CONFIRMATION.DIRECTIONS' | translate }}
    </span>
  </button>

  <a
    *ngIf="location?.phone"
    [attr.aria-label]="('APPOINTMENT.LOCATION.CALL' | translate) + ' ' + location.locationName"
    (click)="setAnalyticsEvent('call-initiated', 'phone-link'); emitSendAnalyticsEvent()"
    href="tel:{{ location.phone }}"
    class="btn btn-lg btn-outline-primary d-flex flex-column flex-wrap gap-1 align-items-center text-uppercase justify-content-center mt-4"
  >
    <span>
      <svg
        class="icon"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.73755 16.0862C9.69227 17.0409 10.6923 17.8989 11.7375 18.6602C12.7827 19.4215 13.8239 20.0244 14.8609 20.4688C15.8979 20.9132 16.8814 21.1354 17.8114 21.1354C18.4287 21.1354 19.0069 21.0223 19.546 20.7959C20.0851 20.5696 20.5727 20.2095 21.0089 19.7157C21.2723 19.4194 21.4739 19.1087 21.6139 18.7836C21.7538 18.4585 21.8237 18.1314 21.8237 17.8022C21.8237 17.547 21.7743 17.3063 21.6756 17.08C21.5768 16.8536 21.4122 16.6623 21.1818 16.5059L18.2065 14.3825C17.7374 14.0533 17.3341 13.8887 16.9966 13.8887C16.7662 13.8887 16.5502 13.9504 16.3485 14.0738C16.1469 14.1973 15.9349 14.366 15.7127 14.58L15.0337 15.259C14.935 15.3578 14.8115 15.4072 14.6634 15.4072C14.5893 15.4072 14.5173 15.3948 14.4473 15.3701C14.3774 15.3454 14.3177 15.3207 14.2683 15.296C13.9556 15.1315 13.5667 14.8475 13.1017 14.4442C12.6367 14.0409 12.1696 13.6047 11.7005 13.1356C11.2396 12.6665 10.8054 12.1994 10.398 11.7344C9.99061 11.2694 9.70462 10.8846 9.54002 10.5801C9.51533 10.5225 9.49063 10.4608 9.46594 10.3949C9.44125 10.3291 9.4289 10.255 9.4289 10.1727C9.4289 10.0245 9.47828 9.90109 9.57704 9.80233L10.256 9.12335C10.47 8.90112 10.6408 8.68919 10.7684 8.48755C10.896 8.2859 10.9597 8.07398 10.9597 7.85177C10.9597 7.50609 10.791 7.09869 10.4536 6.62957L8.3425 3.66665C8.1779 3.4362 7.97832 3.26748 7.74376 3.1605C7.5092 3.0535 7.26023 3 6.99686 3C6.32198 3 5.69647 3.27983 5.12035 3.83949C4.643 4.28392 4.29526 4.77979 4.07715 5.32711C3.85905 5.87443 3.75 6.45673 3.75 7.074C3.75 8.00402 3.96811 8.98343 4.40432 10.0122C4.84051 11.041 5.43514 12.0739 6.18822 13.1109C6.9413 14.1479 7.79107 15.1397 8.73755 16.0862Z"
          fill="currentColor"
        />
      </svg>
      {{ 'APPOINTMENT.LOCATION.CALL' | translate }}
    </span>
  </a>

  <!-- informational website url -->
  <button
    (click)="
      setAnalyticsEvent('website-transfer', 'informational-url');
      setAlertPrompt('pharmacy-website');
      emitOpenExternalUrl(location.informationalUrl)
    "
    class="btn btn-lg btn-outline-primary d-flex flex-column flex-wrap gap-1 align-items-center text-uppercase justify-content-center mt-4"
    *ngIf="location?.informationalUrl"
  >
    <span>
      <svg
        class="icon"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.25 2C6.736 2 2.25 6.486 2.25 12C2.25 17.514 6.736 22 12.25 22C17.764 22 22.25 17.514 22.25 12C22.25 6.486 17.764 2 12.25 2ZM4.25 12C4.25 11.101 4.406 10.238 4.681 9.431L6.25 11L8.25 13V15L10.25 17L11.25 18V19.931C7.311 19.436 4.25 16.072 4.25 12ZM18.58 16.873C17.927 16.347 16.937 16 16.25 16V15C16.25 14.4696 16.0393 13.9609 15.6642 13.5858C15.2891 13.2107 14.7804 13 14.25 13H10.25V10C10.7804 10 11.2891 9.78929 11.6642 9.41421C12.0393 9.03914 12.25 8.53043 12.25 8V7H13.25C13.7804 7 14.2891 6.78929 14.6642 6.41421C15.0393 6.03914 15.25 5.53043 15.25 5V4.589C18.178 5.778 20.25 8.65 20.25 12C20.2498 13.7647 19.6623 15.4791 18.58 16.873Z"
          fill="currentColor"
        />
      </svg>
      {{ 'APPOINTMENT.LOCATION.WEBSITE' | translate }}
    </span>
  </button>
</div>
