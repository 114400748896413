import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  public trackFloodlightEvent(sendTo: string, allowCustomScripts = true): void {
    if (environment.gtagId && environment.gFloodlightId) {
      if (typeof gtag !== 'undefined') {
        try {
          gtag('event', 'conversion', {
            allow_custom_scripts: allowCustomScripts,
            send_to: sendTo,
          });
        } catch (error) {
          console.error('Error while sending event to gtag:', error);
        }
      } else {
        console.warn('gtag is not defined. Skipping event tracking.');
      }
    }
  }
}
