<div class="card mb-4 position-relative" [attr.aria-labelledby]="'location-' + location.locationId">
  <!-- Card click block so the background is clickable -->
  <div class="click-block" (click)="viewLocationDetail.emit()"></div>

  <!-- Location Name and Address -->
  <div class="location-header d-flex gap-3" *ngIf="location">
    <div *ngIf="location?.logoPath">
      <img class="logo" [src]="location.logoPath" aria-hidden alt="" />
    </div>
    <div>
      <h2 [id]="'location-' + location.locationId" class="m-0 text-primary">
        {{ location.locationName }}
      </h2>

      <p class="text-bold m-0 pt-2 pb-2" *ngIf="shouldShowPhoneNumber">
        {{ location.phone }}
      </p>

      <app-location-address
        [address]="location.address"
        [distance]="location.distance"
        [city]="location.city"
        [state]="location.state"
      ></app-location-address>
    </div>
  </div>

  <div #card class="card-body pt-0 position-relative">
    <!-- this will end up containing the html template from the components that extend this class -->
    <ng-content></ng-content>
  </div>

  <!-- Footer: used for Level 3 and 4, set at each integration level -->
  <div *ngIf="shouldShowLocationFooter" class="location-footer text-center">
    <button
      [attr.aria-label]="
        location.locationName + ' ' + ('APPOINTMENT.LOCATION.ADDITIONAL_TIMES_LABEL' | translate)
      "
      (click)="viewLocationDetail.emit()"
      class="btn btn-clear-primary text-normal text-medium p-0 m-0 text-capitalize z-2 position-relative"
    >
      <svg
        width="17"
        height="18"
        viewBox="0 0 17 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="add_circle_outline" clip-path="url(#clip0_12081_13542)">
          <path
            id="add-circle"
            d="M9.20768 5.45964H7.79102V8.29297H4.95768V9.70964H7.79102V12.543H9.20768V9.70964H12.041V8.29297H9.20768V5.45964ZM8.49935 1.91797C4.58935 1.91797 1.41602 5.0913 1.41602 9.0013C1.41602 12.9113 4.58935 16.0846 8.49935 16.0846C12.4093 16.0846 15.5827 12.9113 15.5827 9.0013C15.5827 5.0913 12.4093 1.91797 8.49935 1.91797ZM8.49935 14.668C5.3756 14.668 2.83268 12.1251 2.83268 9.0013C2.83268 5.87755 5.3756 3.33464 8.49935 3.33464C11.6231 3.33464 14.166 5.87755 14.166 9.0013C14.166 12.1251 11.6231 14.668 8.49935 14.668Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_12081_13542">
            <rect width="17" height="17" fill="white" transform="translate(0 0.5)" />
          </clipPath>
        </defs>
      </svg>

      {{ 'APPOINTMENT.LOCATION.ADDITIONAL_TIMES_LABEL' | translate }}
    </button>
  </div>
</div>
