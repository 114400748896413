<div id="eligibility-scroll-container">
  <div class="eligibility-hero mb-4" aria-hidden="true">
    <img
      class="hero d-block d-sm-none d-md-none d-lg-none d-xl-none"
      [src]="vaccinationArmSmallUrl"
      [alt]="'IMAGE.BANDAGE_ARM' | translate"
    />
    <img
      class="hero d-none d-sm-block d-md-block d-lg-block d-xl-block"
      [src]="vaccinationArmLargeUrl"
      [alt]="'IMAGE.BANDAGE_ARM' | translate"
    />
  </div>

  <div>
    <p class="text-primary m-0">{{ 'VACCINE_ELIGIBILITY.QUESTIONS.DISCLAIMER' | translate }}</p>
  </div>

  <accordion [isAnimated]="true">
    <ng-container *ngFor="let section of sections; let i = index">
      <accordion-group class="mt-3" [(isOpen)]="section.isOpen" *ngIf="section.isVisible">
        <div class="d-flex justify-content-between align-items-center gap-1" accordion-heading>
          <div
            class="d-flex flex-column flex-grow-1"
            [class.gap-2]="section.question.answer !== null && section.question.answer.length > 0"
          >
            <h4 class="m-0 p-0 text-primary">{{ section.question.textTC | translate }}</h4>
            <h5
              class="m-0 p-0 text-tertiary text-regular text-bold"
              *ngIf="
                section.question.answer !== null &&
                section.question.answer.length > 0 &&
                !getErrorDisplayText(section.question.answer)
              "
            >
              {{ section.question.answer ? getAnswerDisplayText(section) : '' }}
            </h5>

            <div
              class="answer-error-disclaimer text-primary d-flex gap-2 w-100 mt-2 align-items-center"
              *ngIf="section.question.answer && getErrorDisplayText(section.question.answer)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11.953 2C6.465 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.493 2 11.953 2ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.567 4 11.953 4C16.391 4 20 7.589 20 12C20 16.411 16.411 20 12 20Z"
                  fill="#ED6101"
                />
                <path d="M11 7H13V14H11V7ZM11 15H13V17H11V15Z" fill="var(--vax-warning)" />
              </svg>
              <span
                [innerHtml]="
                  section.question.answer ? getErrorDisplayText(section.question.answer) : ''
                "
              ></span>
            </div>
          </div>
          <div class="caret-icon" [@rotate]="section.isOpen ? 'up' : 'down'">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M8.39062 17.7236L10.276 19.6089L16 13.8849L21.724 19.6089L23.6093 17.7236L16 10.1143L8.39062 17.7236Z"
                fill="var(--vax-primary)"
              />
            </svg>
          </div>
        </div>

        <div class="question">
          <p class="text-primary" *ngIf="section.question.disclaimerTC">
            {{ section.question.disclaimerTC | translate }}
          </p>

          <!-- Single Choice -->
          <div
            class="d-flex flex-column flex-wrap choice-list mb-2"
            *ngIf="section.question.type === vaccineEligibilityQuestionType.SINGLE"
          >
            <div class="eligibility-column" *ngFor="let option of getSelectionOptions(section)">
              <label
                class="text-regular text-bold text-primary d-flex flex-row justify-content-start align-items-center gap-2"
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="single-{{ section.question.id }}"
                  [value]="option.value"
                  [(ngModel)]="section.question.answer"
                  (change)="checkVisibilityConditions(); onSelectionChange(i)"
                />
                <div class="d-flex flex-column">
                  {{ option.displayTC | translate }}
                  <span class="text-small" *ngIf="option.descriptionTC">
                    {{ option.descriptionTC | translate }}
                  </span>
                </div>
              </label>
            </div>
          </div>

          <!-- Multiple Choice -->
          <div
            class="d-flex flex-column flex-wrap choice-list mb-2"
            *ngIf="section.question.type === vaccineEligibilityQuestionType.MULTIPLE"
          >
            <div class="eligibility-column" *ngFor="let option of getSelectionOptions(section)">
              <label
                class="text-regular text-bold text-primary d-flex flex-row justify-content-start align-items-center gap-2"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  [value]="option.value"
                  [(ngModel)]="option.checked"
                  (change)="onCheckboxChange($event, i)"
                />
                <div class="d-flex flex-column">
                  {{ option.displayTC | translate }}
                  <span class="text-small text-secondary text-normal" *ngIf="option.descriptionTC">
                    {{ option.descriptionTC | translate : option.injectedValues || {} }}
                  </span>
                </div>
              </label>
            </div>
          </div>

          <app-vaccine-eligibility-disclaimer-card
            [titleTC]="section.question.whyDisclaimerTitleTC"
            [disclaimerTC]="section.question.whyDisclaimerTC"
            [isSectionOpen]="section.isOpen"
          ></app-vaccine-eligibility-disclaimer-card>
        </div>
      </accordion-group>
    </ng-container>
  </accordion>

  <p class="text-small text-tertiary pt-3">{{ 'VACCINE_ELIGIBILITY.PRN_REVIEW_ID' | translate }}</p>
</div>

<footer class="d-flex gap-4 justify-content-between">
  <div class="d-flex gap-2">
    <button
      id="close-button"
      class="btn btn-outline-primary btn-lg text-uppercase py-0 h-100"
      (click)="toggleIsOpen()"
      [innerHTML]="'VACCINE_ELIGIBILITY.CLOSE_BUTTON' | translate"
    ></button>

    <button
      class="btn btn-outline-primary btn-lg text-uppercase py-0 h-100"
      (click)="restartQuiz()"
      [innerHTML]="'VACCINE_ELIGIBILITY.RESTART_BUTTON' | translate"
    ></button>
  </div>

  <button
    class="btn btn-primary btn-lg btn-submit text-uppercase py-0 h-100"
    *ngIf="isPreviousVaccinationsVisible"
    [disabled]="isSubmitting || !isFormValid"
    (click)="submitForm()"
  >
    <app-loading *ngIf="isSubmitting"></app-loading>
    <span *ngIf="!isSubmitting">
      {{ 'VACCINE_ELIGIBILITY.CHECK_ELIGIBILITY_BUTTON' | translate }}
    </span>
  </button>
</footer>
