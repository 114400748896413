<app-location-integration-level-base
  [location]="location"
  [shouldShowLocationFooter]="false"
  [shouldShowPhoneNumber]="false"
  (viewLocationDetail)="viewLocationDetail.emit(location)"
  *ngIf="location"
>
  <div class="position-relative d-inline-flex">
    <!-- Show Check website for availability if we have bookingUrl with no phone -->
    <p *ngIf="location.bookingUrl" class="text-uppercase text-tertiary text-small mb-0 mt-4">
      {{ 'APPOINTMENT.LOCATION.APPOINTMENT_WEBSITE_CHECK_AVAILABILITY' | translate }}
    </p>
  </div>

  <!-- Level 2 Buttons -->
  <div class="d-flex flex-row gap-3">
    <button
      *ngIf="location.bookingUrl"
      [attr.aria-label]="
        location.locationName +
        ' ' +
        ('APPOINTMENT.LOCATION.APPOINTMENT_WEBSITE_BUTTON' | translate)
      "
      (click)="
        sendGTMEvent('DC-15065329/evax000/apptsite+unique');
        setAnalyticsEvent('website-transfer', 'location-booking-url');
        setAlertPrompt('pharmacy-website');
        openExternalUrl.emit(location.bookingUrl)
      "
      class="btn btn-lg btn-outline-primary d-flex flex-column flex-fill align-items-center justify-content-center text-uppercase w-100 mt-4"
    >
      {{ 'APPOINTMENT.LOCATION.APPOINTMENT_WEBSITE_BUTTON' | translate }}
    </button>
  </div>
</app-location-integration-level-base>
